import { buildMetaTags } from "@fscrypto/domain";
import { sortJourneysByPriority, sortQuestsByPriority } from "@fscrypto/domain/earn";
import type { LoaderFunctionArgs } from "@remix-run/node";
import type { MetaFunction } from "@remix-run/react";
import { BookOpenIcon, TrophyIcon } from "lucide-react";
import { createRequestCaller } from "~/.server/router";
import { Faq } from "~/components/faq-section";
import { JourneyList } from "~/components/journey-list/journey-list";
import { JourneyListCard } from "~/components/journey-list/journey-list-card";
import { PartnerHeader } from "~/components/partner-header";
import { QuestCard } from "~/components/quest-card";
import { QuestTableNoRewards } from "~/components/quest-table-no-rewards";
import { json, useLoaderData } from "~/remix";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const caller = await createRequestCaller(request);

  const [quests, partners, journeysUnsorted, questListItems] = await Promise.all([
    caller.public.quests.all({}),
    caller.public.partners.getQuestPartners(),
    caller.public.journeys.all(),
    caller.public.quests.standalone({}),
  ]);

  const journeys = sortJourneysByPriority(journeysUnsorted);

  return json({ quests, partners, journeys, questListItems });
};

export const meta: MetaFunction = () => {
  return buildMetaTags({
    title: "All Quests | Earn Crypto with Flipside",
    description:
      "Explore all available quests, earn tokens across multiple chains, and grow your crypto portfolio. Join Flipside's community of crypto explorers to shape the onchain economy.",
    canonicalUrl: "https://flipsidecrypto.xyz/earn",
    ogImageUrl: "https://res.cloudinary.com/dsr37ut2z/image/upload/v1731516801/assets/og-images/Earn_1.png",
    twitterImageUrl: "https://res.cloudinary.com/dsr37ut2z/image/upload/v1731516801/assets/og-images/Earn_2.png",
  });
};

export default function Index() {
  const { partners, journeys, questListItems } = useLoaderData<typeof loader>();

  return (
    <main className="container max-w-screen-2xl">
      <PartnerHeader partners={partners} />

      <JourneyList>
        {journeys.map((journey) => (
          <JourneyListCard key={journey.id} journey={journey} />
        ))}
      </JourneyList>

      <div className="flex items-center gap-2 pt-8 pb-4">
        <TrophyIcon className="text-primary/80 size-5" />
        <h2 className="text-2xl font-normal tracking-wide text-foreground/90 border-b border-primary/20 pb-1">
          Quests with Rewards
        </h2>
      </div>
      <p className="text-md text-gray-200/70 mb-4">
        Complete these quests to earn tokens and other rewards. Each quest offers unique challenges and opportunities to
        earn while learning about different protocols.
      </p>
      <div className="grid grid-cols-4 gap-6 py-6">
        {sortQuestsByPriority(questListItems.filter((quest) => quest.isRewardActive)).map((quest) => (
          <QuestCard key={quest.id} questListItem={quest} />
        ))}
      </div>

      <div className="flex items-center gap-2 pt-8 pb-4">
        <BookOpenIcon className="text-primary/80 size-5" />
        <h2 className="text-2xl font-normal tracking-wide text-foreground/90 border-b border-primary/20 pb-1">
          Quests without Rewards
        </h2>
      </div>
      <p className="text-md text-gray-200/70">
        Quests that do not have a reward. You can still participate in these quests to increase your onchain scores!
      </p>
      <div className="py-4">
        <QuestTableNoRewards questListItems={questListItems.filter((quest) => !quest.isRewardActive)} />
      </div>

      <Faq />
    </main>
  );
}
