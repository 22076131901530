import type { QuestEntryStatus, RewardType } from "@fscrypto/domain/earn";

export type JourneyUserState = "LOCKED" | "ELIGIBLE" | "COMPLETED" | "STARTED";
export type JourneyQuestUserState = QuestEntryStatus | "LOCKED" | "ELIGIBLE";
export type JourneyRewardType = RewardType;
export type QuestRewardType = RewardType | undefined;

export type JourneyView = {
  id: string;
  title: string;
  slug: string;
  prereqJourneyId?: string;
  prereqJourneySlug?: string;
  prereqJourneyTitle?: string;
  partnerId: string;
  partnerDisplayName: string;
  partnerSlug: string;
  isOnboarding: boolean;
  isRewardActive: boolean;
  rewardType: JourneyRewardType;
  userState: JourneyUserState;
  quests: JourneyQuestView[];
  tokenAmountAggregated?: number;
  tokenSymbol?: string;
};

export type JourneyQuestView = {
  id: string;
  slug: string;
  title: string;
  requiredQuestId?: string;
  requiredQuestSlug?: string;
  requiredQuestTitle?: string;
  questEntryId?: string;
  questEntryState?: JourneyQuestUserState;
};

export type QuestListItemView = {
  id: string;
  slug: string;
  title: string;
  prereqQuestId?: string;
  prereqQuestSlug?: string;
  prereqQuestTitle?: string;
  partnerId: string;
  partnerDisplayName: string;
  partnerSlug: string;
  isRewardActive: boolean;
  rewardType?: QuestRewardType;
  maxRewardAmount?: number;
  tokenSymbol?: string;
  questEntryId?: string;
  questEntryState?: JourneyQuestUserState;
  sortOrder: number;
  tags: string[];
};

// Sort function for quest entry states
export const sortQuestsByPriority = (items: QuestListItemView[]) => {
  const priorityOrder: Record<JourneyQuestUserState, number> = {
    STARTED: 1,
    ELIGIBLE: 2,
    COMPLETED: 3,
    CLAIMED: 4,
    INELIGIBLE: 5,
    LOCKED: 6,
  };

  return [...items].sort((a, b) => {
    const stateA = a.questEntryState || "LOCKED";
    const stateB = b.questEntryState || "LOCKED";
    const priorityA = priorityOrder[stateA] || 999;
    const priorityB = priorityOrder[stateB] || 999;
    return priorityA - priorityB;
  });
};

export const sortJourneysByPriority = (items: JourneyView[]) => {
  const priorityOrder: Record<JourneyQuestUserState, number> = {
    STARTED: 1,
    ELIGIBLE: 2,
    COMPLETED: 3,
    CLAIMED: 4,
    INELIGIBLE: 5,
    LOCKED: 6,
  };

  return [...items].sort((a, b) => {
    const stateA = a.userState || "LOCKED";
    const stateB = b.userState || "LOCKED";
    const priorityA = priorityOrder[stateA] || 999;
    const priorityB = priorityOrder[stateB] || 999;
    return priorityA - priorityB;
  });
};
